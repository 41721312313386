import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import './ViewProviderProfile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import imageDefault from '../assets/image-square.png'
import langImage from '../assets/lang.png'
import { useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import Select from "react-select";
// import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FaStar } from 'react-icons/fa'
/* <FontAwesomeIcon icon={faPen} /> */

import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";

import userContext from "./userContext/userContext";
import { useContext } from "react";

import { useLocation } from "react-router-dom";

import UserNavbar from "./UserNavbar";

import { HashLoader } from "react-spinners";

import axios from "axios";





const ViewProviderProfile = () => {
    const user_context = useContext(userContext);
    const [loading, setLoading] = useState(false);

    const navigation = useNavigate();
    const inputRef = useRef(null);
    const [image, setImage] = useState("");

    const [userName, setUserName] = useState("User Name");
    const [nameEdit, setNameEdit] = useState(true);

    const [message, setMessage] = useState("Provider Message Here.");
    const [education, setEducation] = useState("Provider's Education");

    const [userInfo, setUserInfo] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum");
    const [infoEdit, setInfoEdit] = useState(true);

    const [addService, setAddService] = useState(false);

    const [services, setServices] = useState([]);
    const [editServiceName, setEditServiceName] = useState("");
    const [editServicePrice, setEditServicePrice] = useState("");
    const [editServiceTime, setEditServiceTime] = useState("");
    const [editServiceDescription, setEditServiceDescription] = useState("");
    const [displayImage, setDisplayImage] = useState("");

    const [aiChat, setAiChat] = useState([{"type":"bot", "message" : "Ask questions about the provider"}]);
    const [aiQuestion, setAiQuestion] = useState("");
    const [reviews, setReviews] = useState([]);

    const [messageText, setMessageText] = useState("");
        //location.state["email"] = sessionStorage.getItem("providerEmail");

    const [messageEdit, setMessageEdit] = useState(true);

    const [chatLoading, setChatLoading] = useState(false);


    const handleAI = async ()=>{
        setChatLoading(true);
        const new_entry = {
            type : "user",
            message : aiQuestion
        };

        var ai_chat = aiChat;
        ai_chat.push(new_entry);

        setAiChat(ai_chat);

        const data = {
            "question" : aiQuestion
        };

        await axios.post("http://127.0.0.1:5000/results",data).then((response)=>{
            console.log(response.data);
            const new_bot_entry = {
                type: "bot",
                message: response.data
            };

            var ai_chat_bot = aiChat;
            ai_chat_bot.push(new_bot_entry);
            setAiChat(ai_chat_bot);
            setChatLoading(false);
        }).catch((err)=>{
            console.log(err);
            setChatLoading(false);
        })
    }




    const handleMessageEdit = () => {
        setMessageEdit(!messageEdit)
    }

    const handleImageClick = (event) => {
        inputRef.current.click();
    }
    const handleInputChange = (e) => {
        setUserName(e.target.value);
    }
    const handleNameClick = async () => {
        if(nameEdit === false)
        {
            setLoading(true);
            const nameInput = document.getElementById("dynamic-input");
            setUserName(nameInput.value);

            const providersCollection = collection(db, "provider_profiles");
            var docId;
            var reqDoc;
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                    }
                })
            })

            var data = reqDoc.data();
            data["name"] = nameInput.value;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);
        }
        setNameEdit(!nameEdit);
        console.log("handle name change clicked!");
    }


    const handleInfoClick = async ()=>{

        if(infoEdit === false)
        {
            setLoading(true);
            const infoInput = document.getElementById("info-text");
            setUserInfo(infoInput.value);
            console.log(infoInput.value);
            const providersCollection = collection(db, "provider_profiles");

            var docId;
            var reqDoc;
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                        console.log("Found Match");
                    }
                })
            })

            var data = reqDoc.data();
            data["info"] = infoInput.value;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);
        }

        setInfoEdit(!infoEdit);
    }

    const handleImageChange = (event) => {
        // const file = event.target.files[0];
        // console.log(file);
        // const img = document.getElementsByClassName("profile-image")[0];
        // img.style.backgroundImage = `url(${file["name"]})`;
        // setImage(event.target.files[0]);
        const file = event.target.files[0];
        setImage(event.target.files[0]);
    }

    //image cropper
    const [sorce, setSorce] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [editor, setEditor] = useState(null);
    const [croppedImageSrc, setCroppedImageSrc] = useState(null);
    const [showEditor, setShowEditor] = useState(true);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () => setSorce(reader.result));
          reader.readAsDataURL(e.target.files[0]);
          setShowEditor(true);
          const saveStyle = document.getElementsByClassName("languages")[0];
          saveStyle.style.marginTop = "10%";

          const infoStyle = document.getElementsByClassName("profile-name-info")[0];
          infoStyle.style.marginLeft = "10%";
        }
      };
    const handleSave = () => {
        console.log("handleSave toggled!")
        if (editor) {
          const canvas = editor.getImageScaledToCanvas();
          const croppedImage = canvas.toDataURL('image/png');
          setCroppedImageSrc(croppedImage);
          setShowEditor(false); // Hide the editor
        }
        const saveStyle = document.getElementsByClassName("languages")[0];
        saveStyle.style.marginTop = "0%";

        const infoStyle = document.getElementsByClassName("profile-name-info")[0];
        infoStyle.style.marginLeft = "1%";
    };



    //Selectbox for languages
    const languages = [
        {value: "ENGLISH", label: "English"},
        {value: "SPANISH", label: "Spanish"},
        {value: "URDU", label: "Urdu"},
        {value: "PUNJABI", label: "Punjabi"},
        {value: "CHINESE ", label: "Chinese"},
        {value: "JAPANESE", label: "Japanese"},
        {value: "BANGALI", label: "Bangali"},
        {value: "HINDI", label: "Hindi"},
        {value: "PROTUGUESE", label: "Portuguese"},
        {value: "RUSSIAN", label: "Russian"},
    ]
    const [displayOptions, setDisplayOptions] = useState(false);
    const [newLanguage, setNewLanguage] = useState(["Languages"]);
    const [languageAdded, setLanguageAdded] = useState(false);
    const [check, setCheck] = useState(true);
    const handleLanguageClick = () => {
        setDisplayOptions(true);
        setCheck(false);
    }
    let events; 
    const handleLanguageChange = (event) => {
        console.log(event);
        events = event;
        console.log(typeof events);
        console.log(events.map(lang => (lang.label)));
        setNewLanguage(events.map(lang => (lang.label)));
        setLanguageAdded(true);
        
    }
    console.log(events);
    const handleAppendClick= async () => {
        setDisplayOptions(false);
        console.log("Languages : ", newLanguage);
        setLoading(true);
        const providersCollection = collection(db, "provider_profiles");
        var docId;
        var reqDoc;
        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    reqDoc = doc;
                    docId = doc.id;
                }
            })
        })

        var data = reqDoc.data();
        data["languages"] = newLanguage;
        await setDoc(doc(db, "provider_profiles", docId), data);
        setLanguageAdded(false);
        setLoading(false);
        setCheck(true);
    }


    //rating
    const [rating, setRating] = useState(3);


    function resizeInput() {
        const input = document.getElementById('dynamic-input');
        const textWidth = getTextWidth(input.value, window.getComputedStyle(input).font);
        input.style.width = `${textWidth}px`;
      }
  
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      }
    // function resizeTextarea() {
    //     const textarea = document.getElementById('dynamic-textarea');
    //     textarea.style.height = 'auto'; // Reset textarea height to auto
    //     textarea.style.height = `${textarea.scrollHeight}px`; // Set textarea height based on content
    //   }

    const location = useLocation();

    const [docLink, setDocLink] = useState("");


    const get_provider_data = async ()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));
        const providerEmail = location.state["email"];
        setLoading(true);
        var data_doc;
        const providersCollection = collection(db, "provider_profiles");
        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                console.log(doc.data());
                console.log(providerEmail);
                if(doc.data()["email"] === providerEmail)
                {
                    console.log("match");
                    setUserName(doc.data()["name"]);
                    setUserInfo(doc.data()["info"]);
                    setMessage(doc.data()["message"]);
                    setEducation(doc.data()["education"]);
                    setNewLanguage(doc.data()["languages"]);
                    setServices(doc.data()["services"]);
                    setDisplayImage(doc.data()["image"]);
                    setReviews(doc.data()["reviews"]);
                    // setDocLink(doc.data()["doc_url"]);
                    // console.log("Services : ",doc.data()["services"] || []);
                    // data_doc = {
                    //     "link" : doc.data()["doc_url"]
                    // };
                }

            })
        });

        setLoading(false);
        
        // if(data_doc["link"] !== "")
        // {
        //     axios.post("http://127.0.0.1:5000/embeddings", data_doc).then((response)=>{
        //         console.log("Response : ", response);
        //         setLoading(false);
        //     });
        // }
        // else {
        //     setLoading(false);
        // }


    }


    const handleAddService = async ()=>{

        if(addService === true)
        {
            setLoading(true);
            const newService = {
                "title" : editServiceName,
                "price" : editServicePrice,
                "time" : editServiceTime,
                "description" : editServiceDescription
            };

            //setServices(oldServices => [...oldServices, newService]);

            var services_ = services;
            services_.push(newService);
            setServices(services_);

            const providersCollection = collection(db, "provider_profiles");

            var docId;
            var reqDoc;

            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                    }
                })
            });

            var data = reqDoc.data();
            data["services"] = services_;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);


        }
        setAddService(!addService);
    }

    const deleteService = async (index)=>{
        setLoading(true);
        var services_ = services;
        services_.splice(index,1);
        setServices(services_);

        const providersCollection = collection(db, "provider_profiles");

        var docId;
        var reqDoc;

        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    docId = doc.id;
                    reqDoc = doc;
                }
            })
        });

        var data = reqDoc.data();
        data["services"] = services_;
        await setDoc(doc(db, "provider_profiles", docId), data);
        setLoading(false);

        
    }

    const message_provider = async ()=>{
        setLoading(true);
        const text = message;
        var isFirstChat = true;

        const usersCollection = collection(db, "user_profiles");
        const providersCollection = collection(db, "provider_profiles");
        var reqDocUser;
        var reqDocProvider;

        var userChat;
        var providerChat;

        var userChatIndex;
        var providerChatIndex;

        var userName;
        var providerName;


        await getDocs(usersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    reqDocUser = doc;
                    userName = doc.data()["name"];
                    for(var i = 0; i<doc.data()["inbox"].length; i++)
                    {
                        if(doc.data()["inbox"][i].user_email === location.state["email"])
                        {
                            isFirstChat = false;
                            userChat = doc.data()["inbox"][i];
                            userChatIndex = i;
                            break;

                        }
                    }
                }
            })
        });

        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === location.state["email"])
                {
                    reqDocProvider = doc;
                    providerName = doc.data()["name"];
                    for(var i = 0; i<doc.data()["inbox"].length; i++)
                    {
                        if(doc.data()["inbox"][i].user_email === user_context.state_["email"]);
                        {
                            isFirstChat = false;
                            providerChat = doc.data()["inbox"][i];
                            providerChatIndex = i;
                            break;
                        }
                    }
                }
            })
        });

        if(isFirstChat === false)
        {
            setLoading(false);
            navigation("/chatbox", {state:{"email":location.state["email"]}});
            return 0;
        }
        else if(isFirstChat === true)
        {
            console.log("First Chat");
            console.log(location.state["email"]);
            const message_content = {
                "sender_name": providerName,
                "sender_email": location.state["email"],
                "text": message
            }

            const chat_user = {
                "user_name": providerName,
                "user_email" : location.state["email"],
                "messages" : [message_content]
            }

            const chat_provider = {
                "user_name" : userName,
                "user_email" : user_context.state_["email"],
                "messages" : [message_content]
            }

            var userData = reqDocUser.data();
            var providerData = reqDocProvider.data();

            providerData["inbox"].push(chat_provider);
            userData["inbox"].push(chat_user);

            console.log("Provider Data : ", providerData);
            console.log("User Data : ", userData);

            console.log(location.state["email"]);
            console.log(user_context.state_["email"]);

            await setDoc(doc(db,"provider_profiles", location.state["email"]),providerData);
            await setDoc(doc(db,"user_profiles", user_context.state_["email"]), userData);

            setLoading(false);
            navigation("/chatbox", {state:{"email":location.state["email"]}});
        }

    }



    useEffect(()=>{
        get_provider_data();
    }, []);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return(
            <HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />
        )
    }


    return (      
        <>
        <UserNavbar/>
        <div className="profile-container">
        
            <div className="profile-content">
                <div className="info">
                    <div className="profile-image">
                    
                    {showEditor &&(
                        <div className="editAvatar" id="editAvatarId">
                            {!sorce &&   <img src={ displayImage === "" ? imageDefault: displayImage} alt="User" />}
                        </div>
                    )}     
                    </div>
                    
                    <div className="profile-name-info">
                        
                            {/* <p>Muhammad Adam</p> */}
                            <div className="profile-name">
                                <input type="text" defaultValue={userName} readOnly={nameEdit} id="dynamic-input" onInput={resizeInput}/>
                                {console.log(nameEdit)}
                                
                                
                            </div>
                            
                            <div className="profile-info">

                                <textarea id="info-text" rows="8" cols="48" type="text" defaultValue={userInfo} readOnly={infoEdit}/>
                                
                            
                            </div>
                        
                    </div>
                    
                </div>
                <div className="services">
                    <p id="services-heading">Services</p>
                    {
                       services &&  services.map((n,i)=>{

                            return (
                                <>
                                <div className="service-card">
                                <div className="service-title">
                                    <p> {n.title} </p>
                                </div>
                                <div className="service-charge">
                                    <p>{n.price} $</p>
                                    <p>/ {n.time} minutes</p>
                                </div>
                                <div className="service-info">
                                    <p>
                                    {n.description}
                                    </p>
                                </div>
                                </div>
                                </>
                            )

                        })
                    }

                </div>

                {/* <div className="message-provider">
                    <div id="header">
                        <p>Message {userName}</p>
                    </div>
                    
                    <textarea placeholder="Message" id="message-text" cols="80" rows="12" readOnly={messageEdit}></textarea>
                    
                </div> */}


                <div className="message-provider">
                    <p>Message {userName}</p>
                    <p> {message}
                    </p>

                    <button onClick={()=>{message_provider();}} style = {{ fontWeight:"500", color:"white", backgroundColor:"#4d7978", borderRadius:"1vw",borderStyle:"none",padding:"1.5% 3.5%",cursor:"pointer"}}>Message</button>
                </div>

                <div className="languages">
                    <br />
                    <div className="language-heading">
                        <p>Languages</p>
                        
                    </div>
                    <div className="languages-list">
                        {displayOptions && <Select id="languageSelect"  options={languages} onChange={handleLanguageChange} isMulti/>}
                        {/* isMulti */}
                    </div>
                    {/* {languageAdded && <div className="languages-list">
                        <img src={langImage} alt="language symbol" />
                        <p>{ newLanguage }</p>
                    </div>} */}
                    {languageAdded && <div className="languages-list">
                        {newLanguage.map(lang=> (
                            <div key={lang} className="language-item"> 
                                <img src={langImage} className="languageImage" alt="language symbol" />
                                <p>{lang}</p>
                            </div>
                        ))}
                        
                        {/* <p>{ newLanguage }</p> */}
                    </div>}

                    {check && <div className="languages-list">
                        {newLanguage.map(lang=> (
                            <div key={lang} className="language-item"> 
                                <img src={langImage} className="languageImage" alt="language symbol" />
                                <p>{lang}</p>
                            </div>
                        ))}
                        
                        {/* <p>{ newLanguage }</p> */}
                    </div>}




                </div>
                <div className="education-container">
                    <p>Education</p>
                    <div className="education">
                    <FontAwesomeIcon icon={faGraduationCap} id="education-icon"/>
                    <p> {education} </p>
                    </div>
                </div>
                <div className="review-container">
                    <p>Reviews</p>

                    {
                        reviews.length >=1 ?
                        <>
                            {
                                reviews.map((n,i)=>{
                                    return(
                                        <>
                                        <div className="review">
                                            <div className="review-message">
                                                <p>
                                                    {n.description}
                                                </p>
                                            </div>
                                            <div className="review-details">
                                                <p>{n.user}</p>
                                                <p> {n.date}</p>
                                                {[...Array(5)].map((star, index) => {
                                                    const currentRating = index + 1;
                                                    return (
                                                        <FaStar 
                                                        className="star" 
                                                        size={18}
                                                        color={currentRating <= (n.rating) ? "#ffc107" : "#e4e5e9"}
                                                        />
                                                    )
                                                })}
                                            </div>

                                        </div>
                                        </>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                        <p>No Reviews given</p>
                        </>
                    }
                    
                </div>

                {
                    docLink === "" ? <p>No Chatbot available</p> : 
                    <>
                <div className="provider-chatbot">
                    <p>Q/A Chatbot</p>
                    {chatLoading === true ?<div className="message-interface-bg"><p style = {{width:"100%", textAlign:"center",marginTop : "10%", color:"white"}}>Loading ... </p> </div> : 
                    <div className="message-interface-bg">
                        <div className="scroll-div">
                            <div className="scroll-object">
                            

                                {
                                    aiChat.map((n,i)=>{
                                        if(n.type === "bot")
                                        {
                                            return (<div className="recieved-message">{n.message}</div>)
                                        }
                                        else if(n.type === "user")
                                        {
                                            return (<div className="sent-message">{n.message}</div>)
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>}
                    <div className="prompt-interface">
                        <input onChange={(e)=>{setAiQuestion(e.target.value);}} type="text" placeholder="Ask a question"/>
                        <button onClick={()=>{handleAI();}}>Ask</button>
                    </div>
                </div>
                    </>
                }


            </div>
            

        </div>
    </> );
}
 
export default ViewProviderProfile;