import './App.css';
import Home from './Components/Home';
import Login from './Components/Login';
import Navbar from './Components/Navbar';
import Signup from './Components/Signup';
import SignupNext from './Components/SignupNext';
import CropImage from './Components/CropImage';
import UserState from './Components/userContext/UserState';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import UserProfile from './Components/UserProfile';
import ProviderProfile from './Components/ProviderProfile';
import ViewProvider from './Components/ViewProviders';
import Review from './Components/Review';
import UserInbox from './Components/UserInbox';
import FindJobs from './Components/FindJobs';
import ApplyJob from './Components/ApplyJob';
import ViewProviderProfile from './Components/ViewProviderProfile';
import PostJob from './Components/PostJob';
import ViewUserProfile from './Components/ViewUserProfile';
import Bookings from './Components/Bookings';
import Chatbox from './Components/Chatbox';
import MyJobs from './Components/MyJobs';

function App() {
  return (
    <>
    <BrowserRouter>
    <UserState>
    <Routes>
      <Route path = "/" element = {<Home/>}/>
      <Route path = "/login" element = {<Login/>}/>
      <Route path = "/signup" element = {<Signup/>}/>
      <Route path = '/signupnext' element = {<SignupNext />}></Route>
      <Route path = '/userProfile' element = {<UserProfile />}></Route>
      <Route path = '/viewUserProfile' element = {<ViewUserProfile />}></Route>
      <Route path = '/cropper' element = {<CropImage />}></Route>
      <Route path = '/review' element = {<Review />}></Route>
      <Route path = '/providerProfile' element = {<ProviderProfile />}></Route>
      <Route path = '/viewProviders' element = { <ViewProvider /> }></Route>
      <Route path = '/viewProviderProfile' element = { <ViewProviderProfile /> }></Route>
      <Route path = "/userInbox" element = { <UserInbox />}></Route>
      <Route path = "/chatbox" element = { <Chatbox />}></Route>
      <Route path = "/findJobs" element = { <FindJobs/> }></Route>
      <Route path = "/myJobs" element = { <MyJobs/> }></Route>
      <Route path = "/apply" element = { <ApplyJob/> }></Route>
      <Route path = "/postJob" element = { <PostJob/> }></Route>
      <Route path = "/bookings" element = { <Bookings/> }></Route>
    </Routes>
    </UserState>
    </BrowserRouter>
    </>
  );
}

export default App;
