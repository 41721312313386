import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import './ProviderProfile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import imageDefault from '../assets/image-square.png'
import langImage from '../assets/lang.png'
import { useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import Select from "react-select";
// import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FaStar } from 'react-icons/fa'
/* <FontAwesomeIcon icon={faPen} /> */

import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";

import userContext from "./userContext/userContext";
import { useContext } from "react";

import ProviderNavbar from "./ProviderNavbar";

import { HashLoader } from "react-spinners";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase/firebase";



const ProviderProfile = () => {
    const user_context = useContext(userContext);
    const [loading, setLoading] = useState(false);

    const navigation = useNavigate();
    const inputRef = useRef(null);
    const [image, setImage] = useState("");

    const [displayPicture, setDisplayPicture] = useState("");
    const [isPictureLoaded, setIsPictureLoaded] = useState(false);

    const [userName, setUserName] = useState("User Name");
    const [nameEdit, setNameEdit] = useState(true);

    const [message, setMessage] = useState("Provider Message Here.");
    const [education, setEducation] = useState("Provider's Education");

    const [userInfo, setUserInfo] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum");
    const [infoEdit, setInfoEdit] = useState(true);

    const [addService, setAddService] = useState(false);

    const [services, setServices] = useState([]);
    const [editServiceName, setEditServiceName] = useState("");
    const [editServicePrice, setEditServicePrice] = useState("");
    const [editServiceTime, setEditServiceTime] = useState("");
    const [editServiceDescription, setEditServiceDescription] = useState("");

    const [review, setReviews] = useState([]);

    const [messageEdit, setMessageEdit] = useState(true);

    const handleMessageEdit = async () => {
        if(messageEdit === false)
        {
            setLoading(true);
            const messageInput = document.getElementById("message-text");
            setMessage(messageInput.value);

            const providersCollection = collection(db, "provider_profiles");
            var docId;
            var reqDoc;
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                    }
                })
            });

            var data = reqDoc.data();
            data["message"] = messageInput.value;
            await setDoc(doc(db, "provider_profiles", docId),data);
            setLoading(false);
        }
        setMessageEdit(!messageEdit)
    }

    const handleImageClick = (event) => {
        inputRef.current.click();
    }
    const handleInputChange = (e) => {
        setUserName(e.target.value);
    }
    const handleNameClick = async () => {
        if(nameEdit === false)
        {
            setLoading(true);
            const nameInput = document.getElementById("dynamic-input");
            setUserName(nameInput.value);

            const providersCollection = collection(db, "provider_profiles");
            var docId;
            var reqDoc;
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                    }
                })
            })

            var data = reqDoc.data();
            data["name"] = nameInput.value;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);
        }
        setNameEdit(!nameEdit);
        console.log("handle name change clicked!");
    }


    const handleInfoClick = async ()=>{

        if(infoEdit === false)
        {
            setLoading(true);
            const infoInput = document.getElementById("info-text");
            setUserInfo(infoInput.value);
            console.log(infoInput.value);
            const providersCollection = collection(db, "provider_profiles");

            var docId;
            var reqDoc;
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                        console.log("Found Match");
                    }
                })
            })

            var data = reqDoc.data();
            data["info"] = infoInput.value;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);
        }

        setInfoEdit(!infoEdit);
    }

    const handleImageChange = (event) => {
        // const file = event.target.files[0];
        // console.log(file);
        // const img = document.getElementsByClassName("profile-image")[0];
        // img.style.backgroundImage = `url(${file["name"]})`;
        // setImage(event.target.files[0]);
        const file = event.target.files[0];
        setImage(event.target.files[0]);
    }

    //image cropper
    const [sorce, setSorce] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [editor, setEditor] = useState(null);
    const [croppedImageSrc, setCroppedImageSrc] = useState(null);
    const [showEditor, setShowEditor] = useState(true);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () => setSorce(reader.result));
          {setImgEditIcon(false)};
          reader.readAsDataURL(e.target.files[0]);
          setShowEditor(true);
          const saveStyle = document.getElementsByClassName("services")[0];
          saveStyle.style.marginTop = "10%";
          saveStyle.style.display = "none";
          const messageStyle = document.getElementsByClassName("message-provider")[0]
          messageStyle.style.display = "none";
          const languagesStyle = document.getElementsByClassName("languages")[0]
          languagesStyle.style.display = "none";
          const educationStyle = document.getElementsByClassName("education-container")[0]
          educationStyle.style.display = "none";
          const reviewStyle = document.getElementsByClassName("review-container")[0]
          reviewStyle.style.display = "none";



          const infoStyle = document.getElementsByClassName("profile-name-info")[0];
          infoStyle.style.marginLeft = "10%";
          infoStyle.style.display = "none";
        }
      };

      function base64ToBlob(base64, mimeType) {
        const bytes = atob(base64.split(',')[1]);
        let buffer = new ArrayBuffer(bytes.length);
        let byteArray = new Uint8Array(buffer);
      
        for (let i = 0; i < bytes.length; i++) {
          byteArray[i] = bytes.charCodeAt(i);
        }
      
        return new Blob([byteArray], {type: mimeType});
      }

      
    const handleSave = async () => {
        console.log("handleSave toggled!")
        if (editor) {

          const canvas = editor.getImageScaledToCanvas();
          const croppedImage = canvas.toDataURL('image/png');
          setCroppedImageSrc(croppedImage);

          const providersCollection = collection(db, "provider_profiles");
          var reqDoc;
          var docId;
          await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    reqDoc = doc;
                    docId = doc.id;
                }
            })
          });


          
          const imageRef = ref(storage, user_context.state_["email"]);
          const imageBlob = base64ToBlob(croppedImage, 'image/jpeg');

          await uploadBytes(imageRef, imageBlob).then((snapshot) => {
            console.log('Uploaded an array!');
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                var data = reqDoc.data();
                data["image"] = downloadURL;
                setDoc(doc(db, "provider_profiles", docId),data);
                console.log('File available at', downloadURL);
              });
    
          });


          console.log("Cropped Image : ", croppedImage);
          setShowEditor(false); // Hide the editor
          setImgEditIcon(true);

        }
        const saveStyle = document.getElementsByClassName("services")[0];
        saveStyle.style.marginTop = "0%";
        saveStyle.style.display = "flex";
        const messageStyle = document.getElementsByClassName("message-provider")[0]
        messageStyle.style.display = "block";
        const languagesStyle = document.getElementsByClassName("languages")[0]
        languagesStyle.style.display = "flex";
        const educationStyle = document.getElementsByClassName("education-container")[0]
        educationStyle.style.display = "block";
        const reviewStyle = document.getElementsByClassName("review-container")[0]
        reviewStyle.style.display = "flex";

        const infoStyle = document.getElementsByClassName("profile-name-info")[0];
        infoStyle.style.marginLeft = "1%";
        infoStyle.style.display = "flex";
  
    };


    const languages = [
        {value: "ENGLISH", label: "English"},
        {value: "SPANISH", label: "Spanish"},
        {value: "URDU", label: "Urdu"},
        {value: "PUNJABI", label: "Punjabi"},
        {value: "CHINESE ", label: "Chinese"},
        {value: "JAPANESE", label: "Japanese"},
        {value: "BANGALI", label: "Bangali"},
        {value: "HINDI", label: "Hindi"},
        {value: "PROTUGUESE", label: "Portuguese"},
        {value: "RUSSIAN", label: "Russian"},
    ]
    const [displayOptions, setDisplayOptions] = useState(false);
    const [newLanguage, setNewLanguage] = useState(["Languages"]);
    const [languageAdded, setLanguageAdded] = useState(false);
    const [check, setCheck] = useState(true);
    const handleLanguageClick = () => {
        setDisplayOptions(true);
        setCheck(false);
    }
    let events; 
    const handleLanguageChange = (event) => {
        console.log(event);
        events = event;
        console.log(typeof events);
        console.log(events.map(lang => (lang.label)));
        setNewLanguage(events.map(lang => (lang.label)));
        setLanguageAdded(true);
        
    }
    console.log(events);
    const handleAppendClick= async () => {
        setDisplayOptions(false);
        console.log("Languages : ", newLanguage);
        setLoading(true);
        const providersCollection = collection(db, "provider_profiles");
        var docId;
        var reqDoc;
        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    reqDoc = doc;
                    docId = doc.id;
                }
            })
        })

        var data = reqDoc.data();
        data["languages"] = newLanguage;
        await setDoc(doc(db, "provider_profiles", docId), data);
        setLanguageAdded(false);
        setLoading(false);
        setCheck(true);
    }


    //rating
    const [rating, setRating] = useState(3);


    function resizeInput() {
        const input = document.getElementById('dynamic-input');
        const textWidth = getTextWidth(input.value, window.getComputedStyle(input).font);
        input.style.width = `${textWidth}px`;
      }
  
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      }
    // function resizeTextarea() {
    //     const textarea = document.getElementById('dynamic-textarea');
    //     textarea.style.height = 'auto'; // Reset textarea height to auto
    //     textarea.style.height = `${textarea.scrollHeight}px`; // Set textarea height based on content
    //   }

    const get_provider_data = async ()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));

        setLoading(true);
        const providersCollection = collection(db, "provider_profiles");
        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                console.log(doc.data());
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    console.log("match");
                    setUserName(doc.data()["name"]);
                    setUserInfo(doc.data()["info"]);
                    setMessage(doc.data()["message"]);
                    setEducation(doc.data()["education"]);
                    setNewLanguage(doc.data()["languages"]);
                    setServices(doc.data()["services"]);
                    setMessage(doc.data()["message"]);
                    setDisplayPicture(doc.data()["image"]);
                    if(doc.data()["image"] === "")
                    {
                        setIsPictureLoaded(false);
                    }
                    else{
                        setIsPictureLoaded(true);
                    }
                    setReviews(doc.data()["reviews"]);
                    setLoading(false);
                }

            })
        })
    }

    const handleAddService = async ()=>{

        if(addService === true)
        {
            setLoading(true);
            const newService = {
                "title" : editServiceName,
                "price" : editServicePrice,
                "time" : editServiceTime,
                "description" : editServiceDescription
            };

            //setServices(oldServices => [...oldServices, newService]);

            var services_ = services;
            services_.push(newService);
            setServices(services_);

            const providersCollection = collection(db, "provider_profiles");

            var docId;
            var reqDoc;

            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        docId = doc.id;
                        reqDoc = doc;
                    }
                })
            });

            var data = reqDoc.data();
            data["services"] = services_;
            await setDoc(doc(db, "provider_profiles", docId), data);
            setLoading(false);


        }
        setAddService(!addService);
    }

    const deleteService = async (index)=>{
        setLoading(true);
        var services_ = services;
        services_.splice(index,1);
        setServices(services_);

        const providersCollection = collection(db, "provider_profiles");

        var docId;
        var reqDoc;

        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    docId = doc.id;
                    reqDoc = doc;
                }
            })
        });

        var data = reqDoc.data();
        data["services"] = services_;
        await setDoc(doc(db, "provider_profiles", docId), data);
        setLoading(false);

        
    }
    const inputDocRef = useRef(null);

    const upload_document = ()=>{
        inputDocRef.current.click();
    }

    const [providerDoc, setProviderDoc] = useState("");
    const [docUrl, setDocUrl] = useState("");

    const handleDocUpload = async (event)=>{
        setLoading(true);
        setProviderDoc(event.target.files[0]);
        console.log(event.target.files[0]);
        var url_;
        const storageRef = ref(storage, `pdfs/${event.target.files[0].name}`);
        uploadBytes(storageRef, event.target.files[0]).then((snapshot)=>{
            console.log("Uploaded File");
            getDownloadURL(ref(storage, `pdfs/${event.target.files[0].name}`)).then((url)=>{
                setDocUrl(url);
                url_ = url;
                console.log("URL : ", url);

                const providersCollection = collection(db, "provider_profiles");
                var reqDoc;
                var docId;
        
                getDocs(providersCollection).then((docs)=>{
                    docs.forEach((doc)=>{
                        if(doc.data()["email"] === user_context.state_["email"])
                        {
                            docId = doc.id;
                            reqDoc = doc;
                            console.log(reqDoc.data() , "reqdoc");
                        }
                    });

                    console.log(reqDoc , "reqdoc");
        
                    var data = reqDoc.data();
                    data["doc_url"] = url_;
                    console.log(data);
                    setDoc(doc(db, "provider_profiles", docId), data);
                    setLoading(false);
                });


            })
        });


    }



    


    useEffect(()=>{
        get_provider_data();
    }, []);

    const [imgEditIcon, setImgEditIcon] = useState(true);

    // useEffect(()=>{
    //     console.log("Services Updated");
    //     console.log("State Services : ", services);
    // }, [services]);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return(
            <HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />
        )
    }

    return (      
        <>
        <ProviderNavbar/>
        <div className="profile-container">
                <div className="profile-content">
                <div className="info">
                    <div className="profile-image">
                    
                    {showEditor &&(
                        <div className="editAvatar" id="editAvatarId">
                            {!sorce && <img src={isPictureLoaded ? displayPicture: imageDefault} alt="User" className="profile-picture"/>}
                            
                            {sorce && (
                                <div >
                                <AvatarEditor
                                    ref={(editor) => setEditor(editor)}
                                    image={sorce}
                                    width={250}
                                    height={250}
                                    border={50}
                                    borderRadius={20}
                                    scale={scale}
                                    rotate={0}
                                    position={position}
                                    onPositionChange={(position) => setPosition(position)}
                                    onScaleChange={(scale) => setScale(scale)}
                                    
                                />
                                
                                <button id="saveImgButton" onClick={handleSave}>Save</button>
                                </div>
                            )}
                        </div>
                    )}
                    {!showEditor && croppedImageSrc && (
                        <div>
                        <img src={croppedImageSrc} alt="Cropped" id="cropped-profile-picture"/>
                        </div>
                    )}
                    
                    </div>
                    {imgEditIcon && <FontAwesomeIcon id ="edit_img" icon={faPen} onClick={handleImageClick}/>}
                    <input type="file" ref={inputRef} onChange={handleFileChange} style={{display: "none"}}/>
                    <div className="profile-name-info">
                        
                            {/* <p>Muhammad Adam</p> */}
                            <div className="profile-name">
                                <input type="text" defaultValue={userName} readOnly={nameEdit} id="dynamic-input" onInput={resizeInput}/>
                                {console.log(nameEdit)}
                                {nameEdit && <FontAwesomeIcon icon={faPen} onClick={handleNameClick} id="nameEdit"/>}
                                {!nameEdit && <FontAwesomeIcon icon={faCheck} onClick={handleNameClick} id="check"/>}
                                
                            </div>
                            
                            <div className="profile-info">

                                <textarea id="info-text" rows="8" cols="48" type="text" defaultValue={userInfo} readOnly={infoEdit}/>
                                {infoEdit && <FontAwesomeIcon icon={faPen} className="editImage" onClick={(e) => {handleInfoClick();}}/>}
                                {!infoEdit && <FontAwesomeIcon icon={faCheck} id="check" onClick={(e) => {handleInfoClick();}}/>}
                            
                            </div>
                        
                    </div>
                    
                </div>
                <div className="services">
                    <p id="services-heading">Services <FontAwesomeIcon id="plus-icon" icon={faPlus} onClick={() => setAddService(!addService)}/></p>
                    {addService && <div className="add-service">
                            <div id="add-title">
                                <label>Title:</label>
                                <input onChange={(e)=>{setEditServiceName(e.target.value);}} placeholder="Service title" type="text" />
                            </div>
                            
                            <div className="money-per-time">
                                <label>Rate:</label>
                                <input onChange={(e)=>{setEditServicePrice(e.target.value)}} placeholder="Dollars" type="number"/>

                                <label>Time:</label>
                                <input onChange={(e)=>{setEditServiceTime(e.target.value);}} placeholder="minutes" type="number"/>
                            </div>
                            
                            <label>Description:</label>
                            <textarea onChange={(e)=>{(setEditServiceDescription(e.target.value));}} placeholder="Service Description" rows={10}></textarea>
                        
                            <button onClick={()=>{handleAddService();}}> Add service </button>
                        
                        </div>}

                    {
                       services &&  services.map((n,i)=>{

                            return (
                                <>
                                <div className="service-card">
                                <div className="service-title">
                                    <p> {n.title} </p>
                                    <FontAwesomeIcon onClick={()=>{deleteService(i);}} icon={faTimesCircle} id="remove-service"/>
                                </div>
                                <div className="service-charge">
                                    <p>{n.price} $</p>
                                    <p>/ {n.time} minutes</p>
                                </div>
                                <div className="service-info">
                                    <p>
                                    {n.description}
                                    </p>
                                </div>
                                </div>
                                </>
                            )

                        })
                    }

                </div>

                <div className="message-provider">
                <div id="header">
                        <p>Message {userName}</p>
                        {messageEdit && <FontAwesomeIcon icon={faPen} onClick={handleMessageEdit} id="messageEdit" />}
                        {!messageEdit && <FontAwesomeIcon icon={faCheck} onClick={handleMessageEdit} id="messageCheck" />}
                    </div>

                    <textarea placeholder="Message" id="message-text" cols="80" rows="12" readOnly={messageEdit}>{message}</textarea>
                </div>

                <div className="languages">
                    <br />
                    <div className="language-heading">
                        <p>Languages</p>
                        {check && <FontAwesomeIcon icon={faPen} id="lang" onClick={handleLanguageClick}/>}
                        {!check && <FontAwesomeIcon icon={faCheck} className="check" onClick={handleAppendClick} style={{marginTop:"2.5%"}}/>} 
                        
                    </div>
                    <div className="languages-list">
                        {displayOptions && <Select id="languageSelect"  options={languages} onChange={handleLanguageChange} isMulti/>}
                        {/* isMulti */}
                    </div>
                    {/* {languageAdded && <div className="languages-list">
                        <img src={langImage} alt="language symbol" />
                        <p>{ newLanguage }</p>
                    </div>} */}
                    {languageAdded && <div className="languages-list">
                        {newLanguage.map(lang=> (
                            <div key={lang} className="language-item"> 
                                <img src={langImage} className="languageImage" alt="language symbol" />
                                <p>{lang}</p>
                            </div>
                        ))}
                        
                        {/* <p>{ newLanguage }</p> */}
                    </div>}

                    {check && <div className="languages-list">
                        {newLanguage.map(lang=> (
                            <div key={lang} className="language-item"> 
                                <img src={langImage} className="languageImage" alt="language symbol" />
                                <p>{lang}</p>
                            </div>
                        ))}
                        
                        {/* <p>{ newLanguage }</p> */}
                    </div>}




                </div>
                <div className="education-container">
                    <p>Education</p>
                    <div className="education">
                    <FontAwesomeIcon icon={faGraduationCap} id="education-icon"/>
                    <p> {education} </p>
                    </div>
                </div>
                <div className="review-container">
                    <p>Reviews</p>

                    {
                        review.length > 0 ?
                        <>

                        {
                            review.map((n,i)=>{
                                return(
                                    <>

                                        <div className="review">
                                            <div className="review-message">
                                                <p>
                                                    {n.description}
                                                </p>
                                            </div>
                                            <div className="review-details">
                                                <p>{n.user}</p>
                                                <p> {n.date}</p>
                                                {[...Array(5)].map((star, index) => {
                                                    const currentRating = index + 1;
                                                    return (
                                                        <FaStar 
                                                        className="star" 
                                                        size={18}
                                                        color={currentRating <= (n.rating) ? "#ffc107" : "#e4e5e9"}
                                                        />
                                                    )
                                                })}
                                            </div>

                                        </div>

                                    </>
                                )
                            })
                        }

                        </> :
                        <>
                        <p>No Ratings Yet.</p>
                        </>
                    }         
                </div>

                <div className="provider-chatbot">
                    <p>Q/A Chatbot</p>
                    <p style = {{width:"50%", fontSize:"2vh"}}>Upload a document that can be used by our chatbot to talk to your potential clients</p>
                    <button onClick={()=>{upload_document();}} style = {{width:"15%", padding:"1%", border:"none", borderRadius:"25px", color:"white", fontWeight:"600", backgroundColor:"#016170", cursor:"pointer"}}>Upload Document</button>
                    <input onChange={(e)=>{handleDocUpload(e);}} ref = {inputDocRef} style = {{display:"none"}} type = "file"/>
                </div>
            </div>

        </div>
    </> );
}
 
export default ProviderProfile;