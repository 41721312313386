import React, {useState, useEffect} from "react";
import "./ApplyJob.css";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import ProviderNavbar from "./ProviderNavbar";
import userContext from "./userContext/userContext";
import { useContext } from "react";
import { collection, getDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ApplyJob = () => {

    const location = useLocation();
    const [description, setDescription] = useState("");
    const [rate, setRate] = useState("");
    const user_context = useContext(userContext);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();

    const apply_job = async ()=>{
        // location.state["email"] = sessionStorage.getItem("jobEmail");
        // location.state["title"] = sessionStorage.getItem("jobTitle");
        // location.state["name"] = sessionStorage.getItem("jobName");
        // location.state["rate"] = sessionStorage.getItem("jobRate");
        // location.state["description"] = sessionStorage.getItem("jobDescription");

        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));

        setLoading(true);
        const providersCollection = collection(db, "provider_profiles");
        const usersCollection = collection(db,"user_profiles");

        var providerName;
        var isFirstChat = true;
        var existingChatIndex;
        var existingChatIndexStudent;
        var userInfo;
        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {   
                    providerName = doc.data()["name"];
                }

            })
        });

        await getDocs(usersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === location.state["email"])
                {
                    console.log("Match FOunddd USer");
                    userInfo = doc;
                }
            })
        });

        const providerInfo = await getDoc(doc(db, "provider_profiles", user_context.state_["email"]));

        for(var i = 0; i < providerInfo.data()["inbox"].length; i++)
        {
            if(providerInfo.data()["inbox"][i]["user_email"] === location.state["email"])
            {
                isFirstChat = false;
                existingChatIndex = i;
            }
        }

        console.log("User Info", userInfo.data());

        for(var i = 0; i< userInfo.data()["inbox"].length; i++)
        {
            if(userInfo.data()["inbox"][i]["user_email"] === user_context.state_["email"])
            {
                existingChatIndexStudent = i;
            }
        }

        const text = "Proposal for Job Title: " + location.state["title"] + "\n" + description + "\n" + "Rate : " + rate;

        const message_content = {
            "sender_name" : providerName,
            "sender_email" : user_context.state_["email"],
            "sender_type" : "Provider",
            "text": text
        };

        if(isFirstChat === true)
        {

            const chat = {
                "user_email" : location.state["email"],
                "user_name" : location.state["name"],
                "messages" : [message_content]
            };

            var data = providerInfo.data();
            data["inbox"].push(chat);

            await setDoc(doc(db,"provider_profiles", user_context.state_["email"]), data);

            const chat_student = {
                "user_email": user_context.state_["email"],
                "user_name": providerName,
                "messages" : [message_content]
            };

            var data_student = userInfo.data();
            data_student["inbox"].push(chat_student);

            await setDoc(doc(db,"user_profiles", location.state["email"]),data_student);


        }
        else if(isFirstChat === false)
        {
            var data = providerInfo.data();
            data["inbox"][existingChatIndex]["messages"].push(message_content);
            await setDoc(doc(db,"provider_profiles", user_context.state_["email"]), data);

            var data_student = userInfo.data();
            data_student["inbox"][existingChatIndexStudent]["messages"].push(message_content);
            await setDoc(doc(db, "user_profiles", location.state["email"]),data_student);

        }

        setLoading(false);

    }



    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }
    if(loading === true)
    {
        return (<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }


    return ( <>
        <ProviderNavbar />
        <div className="ApplyJob-container">
            <div className="ApplyJob-content">
                <div className="ApplyJob-header">
                    <div className="ApplyJob-header-left">
                        <p>{location.state["title"]}</p>
                        <button>{location.state["name"]}</button>
                    </div>
                    <div className="ApplyJob-header-right">
                        <p>{location.state["rate"]}$/60 min</p>
                    </div>
                </div>

                <div className="job-info">
                    <p>
                        {location.state["description"]}
                    </p>
                </div>

                <div className="job-proposal">
                    <textarea onChange={(e)=>{setDescription(e.target.value);}} cols="78" rows="17" placeholder="Write your prosposal."></textarea>
                </div>
                <div className="job-rate">
                <textarea onChange={(e)=>{setRate(e.target.value);}} cols="14" rows="1" placeholder="Rate / 60 mins"></textarea>
                </div>
                <div className="job-apply">
                    <button onClick={()=>{apply_job();}} >Apply</button>
                </div>
            </div>
        </div>
    </> );
}
 
export default ApplyJob;