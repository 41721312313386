import React, {useEffect} from "react";
import "./ViewProviders.css";
import { useState } from "react";

import UserNavbar from "./UserNavbar";

import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";




const ViewProvider = () => {
    const [categoriesOption, setCategoriesOption] = useState('name');
    const [ratesOption, setRatesOption] = useState('all');
    const [providersInfo, setProvidersInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    const [filterBool, setFilterBool] = useState(false);
    const [filteredProviders, setFilteredProviders] = useState([]);

    const [searchText, setSearchText] = useState("");
    const navigation = useNavigate();






    const get_providers = async ()=>{
        setLoading(true);
        const providersCollection = collection(db, "provider_profiles");
        var providers = [];
        

        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                    const name = doc.data()["name"];
                    const info = doc.data()["info"];
                    const services = doc.data()["services"];
                    const email = doc.data()["email"];
    
                    const tempData = {
                        "name":name,
                        "info":info,
                        "services":services,
                        "email":email
                    }
                    console.log("Doc found");
    
                    providers.push(tempData);
            })
        });

        setProvidersInfo(providers);
        setLoading(false);

    }

    const handleCategoryChange = (event) => {
        setCategoriesOption(event.target.value);
    };

    const handleRatesChange = (event) => {
        setRatesOption(event.target.value);
    };


    useEffect(()=>{
        get_providers();

    },[]);

    useEffect(()=>{
        if(searchText === "")
        {
            setFilterBool(false);
            setFilteredProviders([]);
        }
        else{
            if(categoriesOption === "name")
            {
                setFilterBool(true);
                const filtered = providersInfo.filter(provider => 
                    provider.name.toLowerCase().includes(searchText.toLowerCase())
                  );
                setFilteredProviders(filtered);
            }
            else if(categoriesOption === "services")
            {
                setFilterBool(true);
                const filtered = providersInfo.filter(provider => {
                    const servicesMatch = provider.services && provider.services.some(service => 
                        service.title && typeof service.title === 'string' && service.title.toLowerCase().includes(searchText.toLowerCase())
                      );
                    
                    return servicesMatch;
                  });

                  setFilteredProviders(filtered);
            }

        }
    }, [searchText, providersInfo])

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return(
            <HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />
        )
    }

    return ( <>
        <UserNavbar/>
        <div className="view-providers-container">
            <p id="heading">Providers</p>
            <div className="search-bar">
                <input onChange={(e)=>{setSearchText(e.target.value);}} type="text" placeholder="Search"/>

                <select value={categoriesOption} onChange={handleCategoryChange}>
                    <option value="">Categories</option>
                    <option value="name">Search By Name</option>
                    <option value="services">Search By Services</option>
                </select>
            </div>

            {
                filterBool === true ?
                 <>
                 {filteredProviders && filteredProviders.map((n,i)=>{
                    return(
                        <>
                            <div className="provider-container">
                            <div className="provider-name">
                                <p>{n.name}</p>
                            </div>
                            <div className="provider-info">
                                <p>
                                    {n.info}
                                </p>
                            </div>
                            <div className="provider-skills">
                                <ul>
                                {
                                    n.services && n.services.map((x,i)=>{
                                        return(
                                            <li>{x.title}</li>
                                        )

                                    })
                                }
                                </ul>
                            </div>
                            <button onClick={()=>{sessionStorage.setItem("providerEmail", n.email);  navigation("/viewProviderProfile", {state : {"email" : n.email}})}} id="contact-button">Contact</button>
                        </div>
                        </>

                    )
                 })}
                 </> : 
                 <>
            { 

                providersInfo && providersInfo.map((n,i)=>{
                    return(
                        <>
                        <div className="provider-container">
                            <div className="provider-name">
                                <p>{n.name}</p>
                            </div>
                            <div className="provider-info">
                                <p>
                                    {n.info}
                                </p>
                            </div>
                            <div className="provider-skills">
                                <ul>
                                {
                                    n.services && n.services.map((x,i)=>{
                                        return(
                                            <li>{x.title}</li>
                                        )

                                    })
                                }
                                </ul>
                            </div>
                            <button onClick={()=>{navigation("/viewProviderProfile", {state : {"email" : n.email}})}} id="contact-button">Contact</button>
                        </div>
                        </>
                    )
                })
                }
                 
                 </>
            }


            <div style = {{width:"100vw", height:"100px", marginBottom:"5%%"}} id="margin-div">

            </div>
        </div>
    </> );
}
 
export default ViewProvider;