import React, {useState, useEffect} from "react";
import "./UserInbox.css";
import Navbar from "./Navbar";
import MessageImage from "../assets/inbox.jpeg"

import userContext from "./userContext/userContext";
import { useContext } from "react";
import UserNavbar from "./UserNavbar";
import ProviderNavbar from "./ProviderNavbar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";

import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const UserInbox = () => {
    const user_context = useContext(userContext);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();

    const get_messages = async ()=>{

        setLoading(true);
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));
        var Collection;
        if(user_context.state_["current_state"] === "Provider")
        {
            Collection = collection(db, "provider_profiles");
        }
        else if(user_context.state_["current_state"] === "Student")
        {
            Collection = collection(db,"user_profiles");
        }



        await getDocs(Collection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    setMessages(doc.data()["inbox"]);
                    console.log(doc.data()["inbox"]);
                }
            })
        });



        setLoading(false);

        
    }


    useEffect(()=>{
        get_messages();
    },[]);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return(
            <HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />
        )
    }


    return ( <>
    {user_context.state_["current_state"] === "Provider" ? <ProviderNavbar/> : <UserNavbar/>}
    <div className="inbox-container">
        <p>Inbox</p>
        <div className="inbox-content">
            
            <div className="inbox-content-left">


                {
                    messages.length >= 1 ?
                    <>
                    {
                        messages && messages.map((n,i)=>{
                            return(
                                <>
                                    <div className="text-container">
                                        <div className="text-buttons">
                                            <button>{n.user_name}</button>
                                            <button onClick={()=>{sessionStorage.setItem("chatboxEmail",n.user_email); navigation("/chatbox" , {state:{"email":n.user_email}})}}>Open Chat</button>
                                        </div>
                                        <div className="text-info">
                                            <p>
                                                {n.messages[n.messages.length - 1].text.length >= 200 ? n.messages[n.messages.length - 1].text.substring(0,n.messages[n.messages.length - 1].text.substring(0,200).lastIndexOf(' ')) : n.messages[n.messages.length - 1].text}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    </> : 
                    <p>No Messages</p> 
                }

 
            </div>
            <div className="inbox-content-right">
                <img src={MessageImage} alt="" />
            </div>
        </div>
    </div>
    </> );
}
 
export default UserInbox;