import React , {useEffect, useState} from "react";
import "./FindJobs.css"
import ProviderNavbar from "./ProviderNavbar";
import { collection, addDoc, doc, setDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";

import { useNavigate } from "react-router-dom";

import { HashLoader } from "react-spinners";


const FindJobs = () => {


    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredJobs, setFilteredJobs] = useState([]);

    const navigation = useNavigate();

    const get_jobs = async ()=>{
    setLoading(true);
    const jobsCollection = collection(db, "jobs");
    var jobs_ = [];
    await getDocs(jobsCollection).then((docs)=>{
        docs.forEach((doc)=>{
            const data = {
                "title" : doc.data()["title"],
                "email" : doc.data()["user"],
                "name": doc.data()["name"],
                "description": doc.data()["description"],
                "rate":doc.data()["rate"]
            };

            jobs_.push(data);
        })

    });

    setJobs(jobs_);
    setLoading(false);
    }

    useEffect(()=>{
        get_jobs();
    },[]);

    
    useEffect(()=>{
        if(searchText === "")
        {
            setFilteredJobs(jobs);
        }
        else
        {
            const filtered = jobs.filter(job => {
                const jobsMatch = job.title && job.title.toLowerCase().includes(searchText.toLocaleLowerCase())
                
                return jobsMatch;
              });

              setFilteredJobs(filtered);
        }

    },[jobs,searchText]);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return (<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }

    return ( <>
        <ProviderNavbar/>
        <div className="findJobs-container">
            <p>Find Jobs</p>
            <input onChange={(e)=>{setSearchText(e.target.value);}} type="text" id="search" placeholder="Search"/>
            {filteredJobs && filteredJobs.map((n,i)=>{
                return (
                    <>
                        <div className="job-container">
                            <div className="job-container-header">
                                <div className="job-container-header-left">
                                    <p>{n.title}</p>
                                    <button>{n.name}</button>
                                </div>
                                <div className="job-container-header-right">
                                    <p>{n.rate}$/60 min</p>
                                </div>
                            </div>
                            <div className="job-info">
                                <p>
                                    {n.description}
                                </p>
                            </div>
                            <div className="job-apply">
                                <button onClick={()=>{sessionStorage.setItem("jobEmail", n.email); sessionStorage.setItem("jobTitle", n.title); sessionStorage.setItem("jobName",n.name); sessionStorage.setItem("jobRate", n.rate); sessionStorage.setItem("jobDescription", n.description); navigation("/apply", {state : {"email" : n.email, "title":n.title, "name":n.name, "rate":n.rate, "description":n.description}})}}>Apply</button>
                            </div>
                        </div>

                    </>
                )
            })}
        </div>
    </> );
}
 
export default FindJobs;