import React, {useState, useEffect} from "react";
import "./PostJob.css"
import UserNavbar from "./UserNavbar";
import { collection, addDoc, doc, setDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";
import userContext from "./userContext/userContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { HashLoader } from "react-spinners";
const PostJob = () => {

    const [jobTitle, setJobTitle] = useState("");
    const [jobDescription, setJobDescrition] = useState("");
    const [rate, setRate] = useState(0);
    const [loading, setLoading] = useState(false);

    const user_context = useContext(userContext);

    const navigation = useNavigate();


    const post_job = async ()=>{
        setLoading(true);
        

        const usersCollection = collection(db, "user_profiles");
        var name;

        await getDocs(usersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    name = doc.data()["name"];
                    console.log("Name : ", doc.data()["name"]);
                }
            })
        });

        const data = {
            "title":jobTitle,
            "description":jobDescription,
            "rate":rate,
            "user":user_context.state_["email"],
            "name" : name
        };

        console.log(data);

        const docRef = await addDoc(collection(db, "jobs"), data).then((response)=>{
            console.log("Job Posted");
            setLoading(false);
            navigation("/userProfile");
        }).catch((error)=>{
            console.log(error);
            setLoading(false);
        });

        

    };

    useEffect(()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));
    }, []);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }

    if(loading === true)
    {
        return(<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }

    return ( <>
    <UserNavbar/>
    <div className="post-job-container">
        <div className="post-job-content">
            <p>Enter Job Title</p>
            <input onChange={(e)=>{setJobTitle(e.target.value);}} placeholder="Write Job title" type="text" />

            <p>Enter Job Description</p>
            <textarea onChange={(e)=>{setJobDescrition(e.target.value);}}  id="job-description" placeholder="Write job description." rows="10"></textarea>
            <input onChange={(e)=>{setRate(e.target.value);}} type="number" placeholder="Rate / 60 mins" />
            <button onClick={()=>{post_job();}}>Post</button>
        </div>
    </div>

    </> );
}
 
export default PostJob;