import React, {useState, useEffect} from "react";
import Navbar from "./Navbar";
import "./Login.css"
import { auth } from "./firebase/firebase";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import userContext from "./userContext/userContext";
import { useContext } from "react";

import { auth_provider } from "./firebase/firebase";
import { HashLoader } from "react-spinners";
import { sendPasswordResetEmail } from "firebase/auth";
function Login()
{
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const user_context = useContext(userContext);
    const [loading, setLoading] = useState(false);

    const reset_password = async()=>{
        setLoading(true);
        if(email === "")
        {
            alert("Please write your email");
            setLoading(false);
            return 0;
        }

        var auth_;
        if(user_context.state_["current_state"] === "Student")
        {
            auth_ = auth;
        }
        else if(user_context.state_["current_state"] === "Provider")
        {
            auth_ = auth_provider;
        }

        sendPasswordResetEmail(auth_,email).then(()=>{
            setLoading(false);
            alert("Password Reset Link Sent");
        }).catch((error)=>{
            setLoading(false);
            console.log(error);
        });

    }

    const login =  ()=>{

        if(user_context.state_["current_state"] === "Student")
        {
            setLoading(true);
            user_context.update("email", email);
            signInWithEmailAndPassword(auth, email, password).then((response)=>{
                setLoading(false);
                console.log("Login Successfull");
                sessionStorage.setItem("email", email);
                sessionStorage.setItem("login" , "5626");
                sessionStorage.setItem("current_state", "Student");
                navigation("/userProfile");
            }).catch((error)=>{
                setLoading(false);
                alert(error);
                console.log(error);
            })
        }
        else if(user_context.state_["current_state"] === "Provider")
        {
            setLoading(true);
            user_context.update("email", email);
            signInWithEmailAndPassword(auth_provider, email, password).then((response)=>{
                setLoading(false);
                console.log("Login Successfull");
                sessionStorage.setItem("email", email);
                sessionStorage.setItem("login" , "5626");
                sessionStorage.setItem("current_state", "Provider");
                navigation("/providerProfile");
            }).catch((error)=>{
                setLoading(false);
                alert(error);
                console.log(error);
            }) 
        }


    }

    if(loading === true)
    {
        return(<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }

    return(
        <>
        <Navbar/>
        <div className="login-container">
            <div className="login-content">
                <p>Login</p>
                <input onChange={(e)=>{setEmail(e.target.value);}} type="email" required placeholder="Email Address"/>
                <input onChange={(e)=>{setPassword(e.target.value);}} type="password" required placeholder="Password" />
                <p>Don’t have an account ? <p className="signup" onClick={() => {navigation('/signup')}}>Signup</p></p>
                <p style = {{cursor:"pointer"}} onClick={()=>{reset_password();}}>Forgot your password ?</p>
                <button onClick={()=>{login();}} id="login-button">Login</button>
            </div>
        </div>
        
        </>
    );
}

export default Login;