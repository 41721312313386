import React, { useContext, useEffect } from "react";
import "./Bookings.css"
import bookingImage from "../assets/bookings.png"
import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import userContext from "./userContext/userContext";

import ProviderNavbar from "./ProviderNavbar";
import UserNavbar from "./UserNavbar";
import { collection, getDocs, setDoc,doc } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { useNavigate } from "react-router-dom";

import { HashLoader } from "react-spinners";

import { loadStripe } from '@stripe/stripe-js';


const Bookings = () => {
    const [add_Booking, setAdd_Booking] = useState(false);
    const user_context = useContext(userContext);
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const [title, setTitle] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [rate, setRate] = useState("");

    const load_bookings = async ()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));
        setLoading(true);
        var Collection;
        if(user_context.state_["current_state"] === "Provider")
        {
            Collection = collection(db,"provider_profiles");
        }
        else if(user_context.state_["current_state"] === "Student")
        {
            Collection = collection(db,"user_profiles");
        }
        var reqDoc;

        await getDocs(Collection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    setBookings(doc.data()["bookings"]);
                }
            })
        });
        console.log(bookings);

        setLoading(false);

    };

    const handleCheckout = async ()=>{
        const stripe = loadStripe('pk_live_51N8mWkFc9lXrTgNFJjAH0f6Z7THXo33IYyquQsdSoJDetYPtQ4SKJnpM3exENEc9ryL0AW1vvEHMMl4dXgBYjfAF00yFXvs281');
        console.log(stripe);
        if(stripe)
        {
            const { error } = await stripe.redirectToCheckout({
                lineItems:[
                    {
                        price:100,
                        quantity: 1
                    }
                ],
                mode: 'subscription',
                successUrl: 'http://localhost:3000/userProfile',
                cancelUrl: 'http://localhost:3000/bookings',
                customerEmail : 'adambinsaleem123@gmail.com',

            });

            console.warn(error.message);
        }
    }

    const add_booking = async ()=>{
        setLoading(true);
        setAdd_Booking(false);



        const providersCollection = collection(db, "provider_profiles");
        const usersCollections = collection(db,"user_profiles");

        var reqDocUser = "";
        var reqDocProvider;
        var userName;

        await getDocs(providersCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    reqDocProvider = doc;
                }
            })
        });

        await getDocs(usersCollections).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === userEmail)
                {
                    reqDocUser = doc;
                    userName = doc.data()["name"];

                }
            })
        });

        if(reqDocUser === "")
        {
            alert("Invalid Customer Email");
            setLoading(false);
            return 0;
        }

        const booking = {
            "title": title,
            "name": userName,
            "customer_email":userEmail,
            "provider_email":user_context.state_["email"],
            "date":date,
            "time":time,
            "rate":rate,
            "status":"Not Paid"
        };

        var providerData = reqDocProvider.data();
        var userData = reqDocUser.data();

        providerData["bookings"].push(booking);
        userData["bookings"].push(booking);

        await setDoc(doc(db,"provider_profiles", user_context.state_["email"]), providerData);
        await setDoc(doc(db, "user_profiles", userEmail), userData);

        setLoading(false);

        load_bookings();

    }




    const addBooking = () => {
        setAdd_Booking(true);
    }
    const handleBooking = () => {
        setAdd_Booking(false);
    }

    useEffect(()=>{
        load_bookings();
    }, []);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }


    if(loading === true)
    {
        return(<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }

    return ( <>
        {user_context.state_["current_state"] === "Provider" ? <ProviderNavbar/> : <UserNavbar/>}
        <div className="booking-container">
            <div className="booking-content">
                <div className="booking-content-left">
                    <div className="header">
                        <p>Bookings </p>
                        {user_context.state_["current_state"] === "Provider" && <FontAwesomeIcon id="plus" icon={faPlus} onClick={addBooking}/>}
                    </div>
                    {add_Booking && <div className="add-booking">
                        <p>Add a booking</p>
                        <input onChange={(e)=>{setTitle(e.target.value)}} type="text" placeholder="Booking Title" />
                        <input onChange={(e)=>{setUserEmail(e.target.value);}} type="email" placeholder="Customer Email" />
                        <input onChange={(e)=>{setDate(e.target.value);}} type="date" placeholder="day"/>
                        <input onChange={(e)=>{setTime(e.target.value);}} type="text" placeholder="Time"/>
                        <input onChange={(e)=>{setRate(e.target.value);}} type="number" placeholder="Rate / 60 mins"/>
                        <button onClick={()=>{add_booking();}}>Add</button>
                        <button style = {{marginTop:"4%"}} onClick={()=>{setAdd_Booking(false);}}>Close</button>
                    </div>
                    }

                    {
                        bookings.length >=1 ?
                        <>
                        {
                            bookings.map((n,i)=>{
                                return(
                                    <>
                                        <div className="booking-card">
                                            <p>{n.title}</p>
                                            <div className="booking-card-buttons">
                                                <button id="status">{n.status}</button>
                                                <button id="name">{n.name}</button>
                                            </div>
                                            <div className="time">
                                                <p>{n.time}</p>
                                                <p>{n.date}</p>
                                            </div>
                                            <div className="rate-pay">
                                                <p>{n.rate}$/60min</p>
                                                {user_context.state_["current_state"] === "Student" && <button style = {{cursor:"pointer"}} onClick={()=>{console.log("Payment");}}>Pay now</button> }
                                            </div>
                                            
                                        </div>
                                    </>
                                )
                            })
                        }
                        </>
                        :
                        <>
                        <p style = {{fontSize:"3vh"}}>No Bookings at this moment</p>
                        </>
                    }
                
                </div>
                <div className="booking-content-right">
                    <img src={bookingImage} alt="Bookings" id="booking-image"/>
                </div>
            </div>
        </div>
    </> );
}
 
export default Bookings;