import React, {useEffect, useState} from "react";
import "./MyJobs.css"
import UserNavbar from "./UserNavbar"
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";
import userContext from "./userContext/userContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";


const MyJobs = () => {

    const user_context = useContext(userContext);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();

    const load_jobs = async ()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));

        setLoading(true);
        var jobs_ = [];
        const jobsCollection = collection(db,"jobs");
        await getDocs(jobsCollection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["user"] === user_context.state_["email"])
                {
                    jobs_.push(doc);
                }
            })
        });

        setJobs(jobs_);
        setLoading(false);
    };


    const delete_job = async (delDoc)=>{
        setLoading(true);
        await deleteDoc(doc(db,"jobs",delDoc.id));
        load_jobs();
        setLoading(false);
    }

    useEffect(()=>{
        load_jobs();
    }, []);



    if(!(sessionStorage.getItem("login") === "5626"))
    {
       navigation("/login");    
    }

    if(loading === true)
    {
        return (<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />)
    }




    return ( <>
    <UserNavbar/>
    <div className="myJobs-container">
        <div className="myJobs-content">
            <p id="heading">Jobs</p>

            {
                jobs && jobs.map((n,i)=>{
                    return(
                        <>
                            <div className="job-container">
                                <div className="header">
                                    <p>{n.data().title}</p>
                                    <p>{n.data().rate}$/60 min</p>
                                </div>
                                <div className="body">
                                    <p>{n.data().description}</p>
                                </div>
                                <button onClick={()=>{delete_job(n);}}>Delete</button>
                            </div>
                        </>
                    )
                })
            }
        </div>
    </div>
    </> );
}
 
export default MyJobs;