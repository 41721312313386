import React, {useEffect , useState} from "react";
import "./Chatbox.css";
import UserNavbar from "./UserNavbar";
import ProviderNavbar from "./ProviderNavbar";
import { useContext } from "react";
import userContext from "./userContext/userContext";
import { collection, getDocs, setDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { db } from "./firebase/firebase";
import { doc } from "firebase/firestore";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";



function Chatbox()
{
    const user_context = useContext(userContext);
    const location = useLocation();
    const [inbox, setInbox] = useState({});
    const [loading, setLoading] = useState(true);
    const [messageText, setMessageText] = useState("");
    const [localUpdate, setLocalUpdate] = useState(false);
    const [dbUpdate, setDbUpdate] = useState(false);
    const navigation = useNavigate();

    const chatBoxRef = useRef(null);


    const load_messages = async ()=>{
        user_context.update("email", sessionStorage.getItem("email"));
        user_context.update("current_state", sessionStorage.getItem("current_state"));
        //location.state["email"] = sessionStorage.getItem("chatboxEmail");
        var Collection;

        if(user_context.state_["current_state"] === "Provider")
        {
            Collection = collection(db, "provider_profiles");
        }
        else if(user_context.state_["current_state"] === "Student")
        {
            Collection = collection(db, "user_profiles");
        }



        await getDocs(Collection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    for(var i = 0; i<doc.data()["inbox"].length; i++)
                    {
                        if(doc.data()["inbox"][i]["user_email"] === location.state["email"])
                        {
                            setInbox(doc.data()["inbox"][i]);
                            break;
                        }
                    }

                }
            })
        });

        console.log(inbox.messages);

        setLoading(false);



    };


    const update_local_inbox = ()=>{



        setLocalUpdate(true);

        const textInput = document.getElementById("text-input");
        textInput.value = "";

        const message_content = {
            "sender_name" : "User",
            "sender_email" : user_context.state_["email"],
            "sender_type" : user_context.state_["current_state"],
            "text" : messageText
        };

        const newInbox = { messages: [...inbox.messages, message_content] };
        setInbox(newInbox);
        setLocalUpdate(false);
        
    }
    


    const send_message = async ()=>{



        setDbUpdate(true);
        var Collection;

        if(user_context.state_["current_state"] === "Provider")
        {
            Collection = collection(db, "provider_profiles");
        }
        else if(user_context.state_["current_state"] === "Student")
        {
            Collection = collection(db, "user_profiles");
        }

        var userName;

        await getDocs(Collection).then((docs)=>{
            docs.forEach((doc)=>{
                if(doc.data()["email"] === user_context.state_["email"])
                {
                    userName = doc.data()["name"];
                }
            })
        });


        const message_content = {
            "sender_name" : userName,
            "sender_email" : user_context.state_["email"],
            "sender_type" : user_context.state_["current_state"],
            "text" : messageText
        };








        const usersCollection = collection(db, "user_profiles");
        const providersCollection = collection(db, "provider_profiles");

        var reqDocUser;
        var reqDocProvider;

        if(user_context.state_["current_state"] === "Provider")
        {
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        reqDocProvider = doc;
                    }
                })
            });

            await getDocs(usersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === location.state["email"])
                    {
                        reqDocUser = doc;
                    }
                })
            });

            var providerChat;
            var userChat;
            var providerChatIndex;
            var userChatIndex;

            for(var i = 0; i<reqDocProvider.data()["inbox"].length; i++)
            {
                if(reqDocProvider.data()["inbox"][i].user_email === location.state["email"])
                {
                    providerChat = reqDocProvider.data()["inbox"][i];
                    providerChatIndex = i;
                }
            }

            for(var i = 0; i<reqDocUser.data()["inbox"].length; i++)
            {
                if(reqDocUser.data()["inbox"][i].user_email === user_context.state_["email"])
                {
                    userChat = reqDocUser.data()["inbox"][i];
                    userChatIndex = i;
                }
            }

            providerChat.messages.push(message_content);
            userChat.messages.push(message_content);

            var providerData = reqDocProvider.data();
            var userData = reqDocUser.data();

            providerData["inbox"][providerChatIndex] = providerChat;
            userData["inbox"][userChatIndex] = userChat;




            await setDoc(doc(db,"provider_profiles", user_context.state_["email"]),providerData);
            await setDoc(doc(db,"user_profiles", location.state["email"]), userData);
        }

        else if(user_context.state_["current_state"] === "Student")
        {
            await getDocs(providersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === location.state["email"])
                    {
                        reqDocProvider = doc;
                    }
                })
            });

            await getDocs(usersCollection).then((docs)=>{
                docs.forEach((doc)=>{
                    if(doc.data()["email"] === user_context.state_["email"])
                    {
                        reqDocUser = doc;
                    }
                })
            });

            var providerChat;
            var userChat;
            var providerChatIndex;
            var userChatIndex;

            for(var i = 0; i<reqDocProvider.data()["inbox"].length; i++)
            {
                if(reqDocProvider.data()["inbox"][i].user_email === user_context.state_["email"])
                {
                    providerChat = reqDocProvider.data()["inbox"][i];
                    providerChatIndex = i;
                }
            }

            for(var i = 0; i<reqDocUser.data()["inbox"].length; i++)
            {
                if(reqDocUser.data()["inbox"][i].user_email === location.state["email"])
                {
                    userChat = reqDocUser.data()["inbox"][i];
                    userChatIndex = i;
                }
            }

            providerChat.messages.push(message_content);
            userChat.messages.push(message_content);

            var providerData = reqDocProvider.data();
            var userData = reqDocUser.data();

            providerData["inbox"][providerChatIndex] = providerChat;
            userData["inbox"][userChatIndex] = userChat;




            await setDoc(doc(db,"provider_profiles", location.state["email"]),providerData);
            await setDoc(doc(db,"user_profiles", user_context.state_["email"]), userData);
        }

        setLoading(false);
        setDbUpdate(false);

        load_messages();


    }



    useEffect(()=>{
        load_messages();
    }, []);

    useEffect(()=>{
        document.body.style.overflow = "hidden";

        return ()=>{
            document.body.style.overflow = "visible";
        }
    }, []);

    useEffect(()=>{

        const interval = setInterval(()=>{
            if(dbUpdate === false)
            {
                load_messages();
            }

        }, 5000);

        return () => clearInterval(interval);

    },[]);


  useEffect(() => {
    const chatbox = chatBoxRef.current;
    if (chatbox) {
        chatbox.scrollIntoView({
            behavior:'smooth',
            block:'end'
        })
      //chatbox.scrollTop = chatbox.scrollHeight; // Scroll to bottom on render
    }
  }, [inbox]);

    if(!(sessionStorage.getItem("login") === "5626"))
    {
        navigation("/login");
    }


    if(loading === true)
    {
        return (<HashLoader style = {{width:"100vw", height:"100vh"}} color="#016a70" />);
    }

    return(
        <>
        {user_context.state_["current_state"] === "Student" ? <UserNavbar/> : <ProviderNavbar/>}
        <div id="chatbox-container">
            <div id="chatbox">
            {
                    inbox.messages.map((n,i)=>{
                        if(n.sender_email === user_context.state_["email"])
                        {
                            return(<><p style = {{alignSelf:"flex-end", marginRight:"4%"}}>{n.text}</p> <br/></>)
                        }
                        else{
                            return(<><p>{n.text}</p> <br/></>)
                        }

                        
                    })
                }

                <div id="scroll-div" ref = {chatBoxRef}></div>
            </div>

            <div id="chat-input">
                <input id="text-input" onChange={(e)=>{setMessageText(e.target.value);}} type = "text" placeholder="Enter Message Here .."/>
                <button onClick={()=>{update_local_inbox(); if(localUpdate === false){send_message();}}}>Send</button>
            </div>
        </div>
        </>
    );
};

export default Chatbox;