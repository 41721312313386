import React, {useState, useEffect} from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import userContext from "./userContext/userContext";
import { useContext } from "react";
function ProviderNavbar()
{
    const user_context = useContext(userContext);
    const [targetStatus, setTargetStatus] = useState(user_context.state_["target_state"]);

    const navigation = useNavigate();
    const [menuStatus, setMenuStatus] = useState(false);
    const handle_menu = ()=>{
        // const menu = document.getElementById("menu_card");
        // if(menuStatus === false)
        // {
        //     menu.style.transform = "translateX(0)";
        //     setMenuStatus(true);
        // }
        // else{
        //     menu.style.transform = "translateX(1000px)";
        //     setMenuStatus(false);
        // }
        setMenuStatus(!menuStatus);

    }

    const log_out = async ()=>{
        sessionStorage.setItem("email","");
        sessionStorage.setItem("login","2612");
        navigation("/");
    }




    return(
        <>
        <div id="main_navbar">
            <div id="main_nav_left">
                <h2 onClick = {()=>{navigation("/")}}>Campus Duck</h2>
            </div>

            <div style = {{width:"35%"}} id="main_nav_right">
                <p onClick = {()=>{navigation("/providerProfile")}}> Profile</p>
                <p onClick={() => {navigation('/findJobs')}}>Find Jobs</p>
                <p style = {{backgroundColor : "white", color:"black", padding:"0%"}} onClick={() => {navigation('/userInbox')}}>Inbox</p>
                <p onClick={() => {navigation('/bookings')}}>Bookings</p>
                <p style = {{backgroundColor:"#016a70", color:"white", padding:"2%", paddingRight:"7%", paddingLeft:"7%", borderRadius:"25px", marginRight:"5%"}} onClick={() => {log_out();}}>Log Out</p>
            </div>

            <div onClick={()=>{handle_menu();}} id="main_menu">
                <div id="l1"></div>
                <div id="l2"></div>
                <div id="l3"></div>
            </div>

            
        </div>
        { menuStatus && (
            <div id="menu_card">
            <p onClick = {()=>{navigation("/login")}}>Profile</p>
            <p onClick = { () => {navigation("/findJobs")} }>Find Jobs</p>
            <p onClick = { () => {navigation("/userInbox")} }>Inbox</p>
            <p onClick = { () => {log_out();} }>Log Out</p>
        </div>
        )}
        
        </>
    );
}

export default ProviderNavbar;


















